import { productGroupService } from "@/services/ProductGroupService";
import { imageService } from "@/services/ImageService";
import { toast } from "vue3-toastify";
import { i18n } from "./../lang";

const state = {
    productGroups: [],
    isAddingGroup: false,
    editingGroup: null,
    editGroupRequest : {
        loading: false
    },
    addGroupRequest : {
        loading: false
    },
    getGroupsRequest : {
        loading: false
    },
    deleteGroupRequest: {
        loading: false
    }
}

const actions = {
    getProductGroups({commit}){
        commit('setGetGroupsRequest', {loading: true})
        productGroupService.getProductGroups()
            .then((response) => {
                commit('setGetGroupsRequest', {loading: false})
                commit('updateProductGroups', response.data);
            })
            .catch((error) => commit('setGetGroupsRequest', {loading: false, message: error}))
    },
    async updateProductGroup({commit, state}, {productGroup, file, orgId}){
        commit('setEditGroupRequest', {loading: true});
        if (file){
            try {
                const imageUrl = await imageService.uploadImage(file, orgId);
                productGroup.imageUrl = imageUrl;
            } catch (error) {
                console.error("Error uploading image : "+error)
                commit('setEditGroupRequest', {loading: false, message: error})
                return;
            }
        }
        productGroupService.updateProductGroup(productGroup)
            .then((response) => {
                toast.success(i18n.global.t("toast.successEdit"));
                const updatedGroups = state.productGroups.map(x => (x.productGroupId === productGroup.productGroupId ? productGroup : x));
                commit('setEditGroupRequest', {loading: false});
                commit('updateProductGroups', updatedGroups);
                commit('setEditingGroup', false);
            })
            .catch((error) => {
                toast.error(i18n.global.t("toast.errorEdit"));
                commit('setEditGroupRequest', {loading: false, message: error})
            })
    },
    async addProductGroup({commit, state}, {productGroup, file, orgId}){
        commit('setAddGroupsRequest', {loading: true});
        if (file){
            try {
                const imageUrl = await imageService.uploadImage(file, orgId);
                productGroup.imageUrl = imageUrl;
            } catch (error) {
                console.error("Error uploading image : "+error)
                commit('setAddGroupsRequest', {loading: false, message: error})
                return;
            }
        }
        productGroupService.addProductGroup(productGroup)
            .then((response) => {
                toast.success(i18n.global.t("toast.createSuccess"));
                const updatedGroups = state.productGroups;
                updatedGroups.push(response.data)
                commit('setAddGroupsRequest', {loading: false});
                commit('updateProductGroups', updatedGroups);
                commit('setIsAddingGroup', false);
            })
            .catch((error) => {
                toast.error(i18n.global.t("toast.createError"));
                commit('setAddGroupsRequest', {loading: false, message: error})
            })
    },
    deleteProductGroup({commit, state}, productGroup){
        productGroupService.deleteProductGroup(productGroup)
            .then((response) => {
                toast.success(i18n.global.t("toast.deleteSuccess"));
                const updatedGroups = state.productGroups.filter(function(element) {
                    return element.productGroupId !== productGroup.productGroupId;
                });
                commit('updateProductGroups', updatedGroups);
            })
            .catch((error) => {
                toast.error(i18n.global.t("toast.deleteError"));
                commit('setDeleteGroupRequest', {loading: false, message: error})
            });
    },
    changeSpecificField({commit, dispatch, state}, {productGroup, field}){
        if(field === 'userHidden'){
            productGroup.userHidden = !productGroup.userHidden;
        }
        if(field === 'webHidden'){
            productGroup.webHidden = !productGroup.webHidden;
        }
        if(field === 'kitchenPrintEnabled'){
            productGroup.kitchenPrintEnabled = !productGroup.kitchenPrintEnabled;
        }

        //orderIndex & name is done too
        dispatch('updateProductGroup', productGroup);
    }
}

const mutations = {
    setEditingGroup(state, editingGroup){
        state.editingGroup = editingGroup;
        state.isAddingGroup = false;
    },
    setGetGroupsRequest(state, {loading, message}){
        state.getGroupsRequest = {
            loading: loading,
            message: message
        }
    },
    setAddGroupsRequest(state, {loading, message}){
        state.addGroupRequest = {
            loading: loading,
            message: message
        }
    },
    setEditGroupRequest(state, {loading, message}){
        state.editGroupRequest = {
            loading: loading,
            message: message
        }
    },
    setDeleteGroupRequest(state, {loading, message}){
        state.deleteGroupRequest = {
            loading: loading,
            message: message
        }
    },
    updateProductGroups(state, newList){
        state.productGroups = newList;
    },
    setIsAddingGroup(state, isAddingGroup){
        state.isAddingGroup = isAddingGroup;
        state.editingGroup = null;
    }
}

export const productGroup = {
    namespaced: true,
    state,
    actions,
    mutations,
}