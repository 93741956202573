import apiClient from '../helpers/ApiClient'
import { authHeader, handleError } from '../helpers/ApiClient';
import { JWTHelper } from "@/helpers/JWTHelper";

export const imageService = {
    uploadImage
}

// Function to get a presigned URL
async function getPresignedUrl(filename) {
    try {
        const response = await apiClient.get("/image/presign", {
            params: { fileName: filename },
            headers: authHeader(),
        });
        console.log('response', response);
        return response.data.data.presignedURL;
    } catch (error) {
        console.log('error', error);
        return await handleError(error);
    }
}

// Function to upload an image using the presigned URL
async function uploadImage(file, orgId) {
    try {
        const presignedUrl = await getPresignedUrl(file.name);
        console.log('presignedUrl', presignedUrl);
        const fileUrl = presignedUrl.split('?')[0];
        const response = await apiClient.put(presignedUrl, file, {
            headers: {
                'Content-Type': file.type,
                'x-amz-meta-orgid': orgId,
            },
        });
        return fileUrl;
    } catch (error) {
        return await handleError(error);
    }
}