import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "edit-row-btn" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditMessageDialog = _resolveComponent("EditMessageDialog")!
  const _component_popup_view = _resolveComponent("popup-view")!
  const _component_confirm_delete_dialog = _resolveComponent("confirm-delete-dialog")!
  const _component_page_header = _resolveComponent("page-header")!
  const _component_Column = _resolveComponent("Column")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_popup_view, {
      isVisible: _ctx.currentMessage,
      onClose: _cache[1] || (_cache[1] = ($event: any) => (!_ctx.currentMessage))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_EditMessageDialog, {
          onCancelEditAction: _cache[0] || (_cache[0] = ($event: any) => (_ctx.currentMessage = false)),
          onSubmitEditAction: _ctx.submitMessage
        }, null, 8, ["onSubmitEditAction"])
      ]),
      _: 1
    }, 8, ["isVisible"]),
    _createVNode(_component_popup_view, {
      isVisible: _ctx.deleteOpenDialog,
      onClose: _cache[3] || (_cache[3] = ($event: any) => (!_ctx.deleteOpenDialog))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_confirm_delete_dialog, {
          kitchenMessage: _ctx.currentSelect,
          onSubmitDeleteAction: _ctx.submitDelete,
          onCancelDeleteAction: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deleteOpenDialog = false))
        }, null, 8, ["kitchenMessage", "onSubmitDeleteAction"])
      ]),
      _: 1
    }, 8, ["isVisible"]),
    _createVNode(_component_page_header, {
      onActionClick: _ctx.openAddingDialog,
      iconName: "kitchen2.svg",
      actionIconName: "plus.svg",
      actionText: _ctx.$t('kitchenPageStrings.addMessage'),
      headerTitle: _ctx.$t('kitchenPageStrings.name')
    }, null, 8, ["onActionClick", "actionText", "headerTitle"]),
    _createVNode(_component_DataTable, {
      value: _ctx.getKitchenMessages,
      "filter-display": "row",
      loading: _ctx.loading,
      editMode: "cell"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "text",
          header: "Messages"
        }, {
          body: _withCtx(({ data }) => [
            _createElementVNode("span", null, _toDisplayString(data.text), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, { header: "Actions" }, {
          body: _withCtx(({data}) => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("button", {
                title: "Delete",
                class: "delete",
                onClick: ($event: any) => (_ctx.openDeleteDialog(data.id))
              }, [
                _createVNode(_component_inline_svg, {
                  src: require('@/assets/icons/trash.svg')
                }, null, 8, ["src"])
              ], 8, _hoisted_2)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value", "loading"])
  ]))
}