<template>
  <div>
    <popup-view :isVisible="currentMessage" @close="!currentMessage">
      <EditMessageDialog
          @cancelEditAction="currentMessage = false"
          @submitEditAction="submitMessage"/>
    </popup-view>

    <popup-view :isVisible="deleteOpenDialog" @close="!deleteOpenDialog">
      <confirm-delete-dialog :kitchenMessage ="currentSelect" @submitDeleteAction="submitDelete"
                             @cancelDeleteAction="deleteOpenDialog = false"/>
    </popup-view>

    <page-header
        @action-click="openAddingDialog"
        iconName="kitchen2.svg"
        actionIconName="plus.svg"
        :actionText="$t('kitchenPageStrings.addMessage')"
        :headerTitle="$t('kitchenPageStrings.name')"
    />

    <DataTable
        :value="getKitchenMessages"
        filter-display="row"
        :loading="loading"
        editMode="cell"
    >

      <Column field="text" header="Messages">
        <template #body="{ data }">
          <span>{{ data.text }}</span>
        </template>
      </Column>

      <Column header="Actions" >
        <template #body="{data}">
          <div class="edit-row-btn">
            <button title="Delete" class="delete" @click="openDeleteDialog(data.id)">
              <inline-svg :src="require('@/assets/icons/trash.svg')" />
            </button>
          </div>
        </template>
      </Column>

    </DataTable>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import { mapActions, mapGetters } from "vuex";
import PageHeader from "@/views/components/PageHeader.vue";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import PopupView from "@/views/components/PopupView.vue";
import ConfirmDeleteDialog from "@/views/dialogs/ConfirmDeleteDialog.vue";
import EditMessageDialog from "@/views/dialogs/EditMessageDialog.vue";

export default defineComponent({
  name: "KitchenPage",
  data() {
    return {
      loading: false,
      deleteOpenDialog: false,
      currentMessage: false,
      currentSelect: {},
    };
  },
  components: {
    EditMessageDialog,
    ConfirmDeleteDialog,
    PopupView, PageHeader,
    DataTable,
    Column
  },
  computed: {
    ...mapGetters("kitchen", ["getKitchenMessages"]),
  },
  methods: {
    ...mapActions("kitchen", ["fetchKitchenMessages", "deleteKitchenMessage", "addKitchenMessage"]),

    openDeleteDialog(id: number) {
      this.deleteOpenDialog = true;
      this.currentSelect = id;
    },

    openAddingDialog() {
      this.currentMessage = true;
    },

    async submitMessage(text:string) {
      try {
        await this.addKitchenMessage(text);
        this.currentMessage = false;
      } catch (error) {
        console.error("Error adding message:", error);
      }
    },

    submitDelete() {
      this.deleteKitchenMessage(this.currentSelect);
      this.deleteOpenDialog = false;
    }
  },
  mounted() {
    this.fetchKitchenMessages();
  },
})
</script>

<style scoped lang="scss">
</style>