import apiClient from "../helpers/ApiClient";
import { handleError, authHeader } from "../helpers/ApiClient";

export const kitchenService = {
    getKitchenMessage,
    postKitchenMessage,
    deleteKitchenMessage
}
function getKitchenMessage(){
    return apiClient
        .get("/kitchen_message", {headers: authHeader()})
        .then((response) => {
            return response.data;
        })
        .catch((error) => handleError(error));
}

async function postKitchenMessage(text) {
    return apiClient
        .post("/kitchen_message", text, { headers: authHeader() })
        .then((response) => {
            return response.data;
        })
        .catch((error) => handleError(error));
}

async function deleteKitchenMessage(kitchenMessageId) {
    return apiClient
        .delete(`/kitchen_message/${kitchenMessageId}`, { headers: authHeader() })
        .then((response) => {
            console.log("Delete response:", response.data);
            return response.data;
        })
        .catch((error) => {

            handleError(error)
        });
}


