import { kitchenService } from "@/services/kitchenService";
import { toast } from "vue3-toastify";
import { i18n } from "./../lang";

const state = {
    message: []
};

const getters = {
    getKitchenMessages(state) {
        return state.message;
    },
};

const mutations = {
    SET_KITCHEN_MESSAGES(state, messages) {
        state.message = messages;
    },
    ADD_MESSAGE(state, message) {
        state.message.push(message);
    },
};

const actions = {
    async fetchKitchenMessages({ commit }) {
        try {
            const response = await kitchenService.getKitchenMessage();
            commit("SET_KITCHEN_MESSAGES", response.data);
        } catch (error) {
            console.error("Error fetching kitchen messages:", error);
        }
    },
    async addKitchenMessage({ commit, dispatch }, text) {
        try {
            const newMessage = await kitchenService.postKitchenMessage({ text });
            commit("ADD_MESSAGE", newMessage);
            toast.success(i18n.global.t("toast.createSuccess"));
            await dispatch("fetchKitchenMessages");
        } catch (error) {
            toast.error(i18n.global.t("toast.createError"));
            console.error("Error adding kitchen message:", error);
        }
    },
    async deleteKitchenMessage({ dispatch }, kitchenMessageId) {
        try {
            await kitchenService.deleteKitchenMessage(kitchenMessageId);
            toast.success(i18n.global.t("toast.deleteSuccess"));
            await dispatch("fetchKitchenMessages");
        } catch (error) {
            toast.error(i18n.global.t("toast.deleteError"));
            console.error("Error deleting kitchen message:", error);
        }
    }
};

export const kitchen = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
